import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Form, Button, Row, Col, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useProfileMutation, useWishlistQuery, useRemoveFavoritesMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { FaTimes } from "react-icons/fa";

const ProfileScreen = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [removingItemId, setRemovingItemId] = useState(null); // Track the ID of the item being removed

    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);

    const { data: wishlist, error: isError, isLoading: isLoadingWishlist, refetch } = useWishlistQuery(userInfo?._id);
    const [removeFavorites, { isLoading: isRemoving }] = useRemoveFavoritesMutation();
    const [updateProfile, { isLoading: loadingUpdateProfile }] = useProfileMutation();

    useEffect(() => {
        if(userInfo) {
            setName(userInfo.name);
            setEmail(userInfo.email);
        }
    }, [userInfo]);

    const submitHandler = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error('Passwords do not match');
        } else {
            try {
                const res = await updateProfile({ _id:userInfo._id, name, email, password }).unwrap();
                dispatch(setCredentials(res));
                toast.success('Profile updated successfully');
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const handleRemoveFromWishlist = async (productId) => {
        setRemovingItemId(productId); // Set the ID of the item being removed
        try {
            await removeFavorites({ productId }).unwrap();
            // toast.success('Item removed from wishlist successfully');
            refetch(); // Refetch the wishlist to update the UI
        } catch (error) {
            toast.error('Failed to remove item from wishlist');
        } finally {
            setRemovingItemId(null); // Clear the ID after removal process
        }
    };

    return (
        <Row>
            <Col md={3}>
                <h2>User Profile</h2>
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='name' className={'my-2'}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type='name'
                            placeholder='Enter name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='email address' className={'my-2'}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type='email'
                            placeholder='Enter email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='password' className={'my-2'}>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type='password'
                            placeholder='Enter password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='confirmPassword' className={'my-2'}>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type='password'
                            placeholder='Confirm password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Button type="submit" className="mt-2 blue-outline">
                        Update User Details
                    </Button>

                    {loadingUpdateProfile && <Loader />}
                </Form>
            </Col>
            <Col md={9}>
                <h2>Wishlist</h2>
                {isLoadingWishlist ? <Loader /> : isError ? <Message variant="danger">Failed to load wishlist</Message> : (
                    <Table striped bordered hover responsive className="table-sm">
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Remove</th>
                        </tr>
                        </thead>
                        <tbody>
                        {wishlist && wishlist.map(item => (
                            <tr key={item._id}>
                                <td style={{ textAlign: 'center' }}>
                                    <a href={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Image
                                            src={item.image}
                                            alt={item.name}
                                            fluid
                                            rounded
                                            style={{ width: '75px', height: '75px', marginBottom: '2px' }}
                                            className="img-thumbnail"
                                        />
                                    </a>
                                </td>
                                <td>
                                    <a href={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {item.name}
                                    </a>
                                </td>
                                <td>
                                    <a href={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        £{item.price}
                                    </a>
                                </td>
                                <td>
                                    <Button
                                        type="button"
                                        variant="light"
                                        onClick={() => handleRemoveFromWishlist(item._id)} // Call the function with item ID
                                        disabled={isRemoving && removingItemId === item._id} // Disable button only for the item being removed
                                    >
                                        {isRemoving && removingItemId === item._id ? (
                                            <Loader size="sm" /> // Show a loader for the specific item being removed
                                        ) : (
                                            <FaTimes color='red' />
                                        )}
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                )}
            </Col>
        </Row>
    );
};

export default ProfileScreen;
