import { USERS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/auth`,
                method: "POST",
                body: data,
            }),
        }),
        register: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}`,
                method: "POST",
                body: data,
            }),
        }),
        removeFavorites: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/favorites/${data.productId}`,
                method: "DELETE",
                body: data,
            }),
        }),
        favorites: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/favorites/${data.productId}`,
                method: "PUT",
                body: data,
            }),
        }),
        wishlist: builder.query({
            query: (userId) => `${USERS_URL}/${userId}/favorites`,
            providesTags: ['Wishlist'],
        }),
        favoriteStatus: builder.query({
            query: (productId) => ({
                url: `${USERS_URL}/favorites/status/${productId}`,
                method: "GET",
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: `${USERS_URL}/logout`,
                method: "POST",
            }),
        }),
        profile: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/profile`,
                method: "PUT",
                body: data,
            }),
        }),
        getUsers: builder.query({
            query: () => ({
                url: `${USERS_URL}`,
            }),
            providesTags: ["Users"],
            keepUnusedDataFor: 5,
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `${USERS_URL}/${userId}`,
                method: "DELETE",
            }),
        }),
        getUserDetails: builder.query({
            query: (userId) => ({
                url: `${USERS_URL}/${userId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/${data.userId}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Users"],
        }),
        resetPassword: builder.mutation({
            query: ({ email }) => ({
                url: `${USERS_URL}/reset-password/`,
                method: 'POST',
                body: { email },
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        resetPasswordUser: builder.mutation({
            query: ({ token, password }) => ({
                url: `${USERS_URL}/reset/${token}`, // Ensure this matches the backend route
                method: 'POST',
                body: { password },
            }),
        }),
        verifyEmail: builder.mutation({
            query: ({ token }) => ({
                url: `${USERS_URL}/verify-email`,
                method: 'POST',
                body: { token }, // Send the token in the body
            }),
            transformResponse: (response) => response.data,
        }),
        resendVerificationEmail: builder.mutation({
            query: ({ email }) => ({
                url: `${USERS_URL}/resend-verification`,
                method: 'POST',
                body: { email },
            }),
            transformResponse: (response) => response.data,
        }),
    }),
});

export const {
    useLoginMutation,
    useResetPasswordMutation,
    useResetPasswordUserMutation,
    useLogoutMutation,
    useRegisterMutation,
    useFavoritesMutation,
    useRemoveFavoritesMutation,
    useFavoriteStatusQuery,
    useWishlistQuery,
    useProfileMutation,
    useGetUsersQuery,
    useDeleteUserMutation,
    useGetUserDetailsQuery,
    useUpdateUserMutation,
    useVerifyEmailMutation,
    useResendVerificationEmailMutation,
} = usersApiSlice;