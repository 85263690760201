import React, { useRef, useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Loader from './Loader';
import Message from './Message';
import { useGetFontProductsQuery, useGetProductsNavQuery } from '../slices/productsApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@awesome.me/kit-367a27fd96/icons/classic/light';
import { faHouse, faChevronLeft, faChevronRight, faGalleryThumbnails } from '@awesome.me/kit-367a27fd96/icons/classic/light';

const HeaderNav = ({ onProductSelect, clearHomeScreen }) => {
    const { data: fontProducts, isLoading: isFontLoading, error: fontError } = useGetFontProductsQuery();
    const { data: allProducts, isLoading: isAllLoading, error: allError } = useGetProductsNavQuery({ keyword: '', pageNumber: 1 });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detect initial mobile view
    const scrollRef = useRef(null);
    const scrollInterval = useRef(null); // For auto scroll

    // Toggle for hover scroll feature
    const HOVER_SCROLL_ENABLED = true; // Set to true to enable hover scroll, false to disable it

    // Handle window resize to check if it's mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Start automatic slow scrolling
    useEffect(() => {
        if (isMobile || !scrollRef.current) return;

        if (HOVER_SCROLL_ENABLED) {
            startAutoScroll(); // Start auto-scrolling when hover scroll is enabled
        }

        return () => stopScroll(); // Clean up on component unmount
    }, [isMobile]);

    const startAutoScroll = () => {
        stopScroll(); // Stop any existing interval

        const scrollSpeed = 150; // Higher value means slower scroll speed
        const scrollAmount = 1; // Scroll amount for each interval

        scrollInterval.current = setInterval(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollBy({ left: scrollAmount });
            }
        }, scrollSpeed);
    };

    const stopScroll = () => {
        if (scrollInterval.current) {
            clearInterval(scrollInterval.current);
            scrollInterval.current = null; // Ensure the interval is fully cleared
        }
    };

    const handleMouseEnter = () => {
        if (HOVER_SCROLL_ENABLED) {
            stopScroll(); // Stop auto-scrolling when mouse enters
        }
    };

    const handleMouseLeave = () => {
        if (HOVER_SCROLL_ENABLED) {
            startAutoScroll(); // Resume auto-scrolling when mouse leaves
        }
    };

    // Scroll left and right using chevrons
    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -100, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 100, behavior: 'smooth' });
        }
    };

    const renderProducts = (products, onProductSelect) => {
        return products.map((product, index) => {
            const iconName = `${product.font.charAt(0) + product.font.slice(1)}`;
            const IconComponent = Icons[iconName] ? Icons[iconName] : Icons.faQuestionCircle;
            return (
                <LinkContainer
                    key={index}
                    to={`/search/${product.productType}`}
                    onClick={() => onProductSelect(product.productTypeDescription)}
                >
                    <Nav.Link
                        className="icon-link-container"
                        draggable="false"
                        onMouseEnter={HOVER_SCROLL_ENABLED ? handleMouseEnter : null} // Stop scrolling on hover if enabled
                        onMouseLeave={HOVER_SCROLL_ENABLED ? handleMouseLeave : null} // Resume scrolling on hover exit if enabled
                    >
                        <div className="icon-wrapper" draggable="false">
                            {IconComponent && <FontAwesomeIcon icon={IconComponent} color="black" className="icon" draggable="false" />}
                            <span className="icon-text" draggable="false">{product.productType}</span>
                        </div>
                    </Nav.Link>
                </LinkContainer>
            );
        });
    };

    if (isFontLoading || isAllLoading) return <Loader />;
    if (fontError || allError) return <Message variant="danger">{fontError || allError}</Message>;

    return (
        <header>
            <Navbar
                className="horizontal-scroll-nav headerNavSpacing"
                style={{ cursor: 'grab', backgroundColor: "#ffffff", height: '100px'}}
            >
                <Container fluid>
                    <Navbar.Toggle className="custom-toggle" aria-controls="basic-navbar-nav" />
                    <div style={{ display: 'flex', width: '100%'}}>
                        <button
                            className="nav-arrow left"
                            onClick={scrollLeft} // Scroll left on click
                            onMouseEnter={HOVER_SCROLL_ENABLED ? handleMouseEnter : null} // Stop auto-scroll on hover if enabled
                            onMouseLeave={HOVER_SCROLL_ENABLED ? handleMouseLeave : null} // Resume auto-scroll on leave if enabled
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <div
                            ref={scrollRef}
                            style={{ overflowX: 'auto', flexGrow: 1 }}
                            onMouseEnter={HOVER_SCROLL_ENABLED ? handleMouseEnter : null} // Stop scrolling on hover if enabled
                            onMouseLeave={HOVER_SCROLL_ENABLED ? handleMouseLeave : null} // Resume scrolling on hover exit if enabled
                        >
                            <nav className="navbar-nav">
                                <LinkContainer to='/' onClick={() => clearHomeScreen()}>
                                    <Nav.Link
                                        className="icon-link-container"
                                        draggable="false"
                                        onMouseEnter={HOVER_SCROLL_ENABLED ? handleMouseEnter : null} // Stop scrolling on hover if enabled
                                        onMouseLeave={HOVER_SCROLL_ENABLED ? handleMouseLeave : null} // Resume scrolling on hover exit if enabled
                                    >
                                        <div className="icon-wrapper" draggable="false">
                                            <FontAwesomeIcon icon={faHouse} size="2x" className="icon" draggable="false"/>
                                            <span className="icon-text" draggable="false">Home</span>
                                        </div>
                                    </Nav.Link>
                                </LinkContainer>
                                <LinkContainer to={'/search/'} >
                                    <Nav.Link
                                        className="icon-link-container"
                                        draggable="false"
                                        onMouseEnter={HOVER_SCROLL_ENABLED ? handleMouseEnter : null} // Stop scrolling on hover if enabled
                                        onMouseLeave={HOVER_SCROLL_ENABLED ? handleMouseLeave : null} // Resume scrolling on hover exit if enabled
                                    >
                                        <div className="icon-wrapper" draggable="false">
                                            <FontAwesomeIcon icon={faGalleryThumbnails} size="2x" className="icon" draggable="false"/>
                                            <span className="icon-text" draggable="false">All Items</span>
                                        </div>
                                    </Nav.Link>
                                </LinkContainer>
                                {renderProducts(fontProducts, onProductSelect)}
                            </nav>
                        </div>
                        <button
                            className="nav-arrow right"
                            onClick={scrollRight} // Scroll right on click
                            onMouseEnter={HOVER_SCROLL_ENABLED ? handleMouseEnter : null} // Stop auto-scroll on hover if enabled
                            onMouseLeave={HOVER_SCROLL_ENABLED ? handleMouseLeave : null} // Resume auto-scroll on leave if enabled
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                </Container>
            </Navbar>
        </header>
    );
};

export default HeaderNav;
